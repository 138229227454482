<template>
  <div>
    <div v-if="loadingFormData" class="text-center text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong class="ml-1">{{ $t('CATALOG_LOADING_LABEL', {catalog: $t('DIESEL_CONTROL')})  | capitalize }}</strong>
    </div>
    <b-card bg-variant="dark" text-variant="light" v-else :title="$t('FORMS_GENERAL_DATA_TITLE') | capitalize">
      <b-card-text>
        <pre v-if="debug">{{ form }}</pre>
        <b-form @submit.prevent="save">
          <!-- travelCount text input -->
<b-form-group
        id="travels-form-group"
        :label="$t('DIESEL_CONTROL_TRAVEL_COUNT_LABEL') | capitalize"
        label-for="travelCount-input"
        :state="state('travelCount')">
    <b-form-input class="w-100 ml-1" id="travels-input" type="text"
                  v-model="$v.form.travelCount.$model"
                  :state="state('travelCount')"
                  trim></b-form-input>

    <b-form-invalid-feedback id="travels-input-feedback">
        <div v-for="error in errors('travelCount')" :key="error.error">
            {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('DIESEL_CONTROL_TRAVEL_COUNT_LABEL')},})  | capitalize}}
        </div>
    </b-form-invalid-feedback>
</b-form-group>
<!-- travelCount text input -->

<!-- pointOfRefuel text input -->
<b-form-group
        id="point-of-refuel-form-group"
        :label="$t('DIESEL_CONTROL_POINT_OF_REFUEL_LABEL') | capitalize"
        label-for="pointOfRefuel-input"
        :state="state('pointOfRefuel')">
    <b-form-input class="w-100 ml-1" id="point-of-refuel-input" type="text"
                  v-model="$v.form.pointOfRefuel.$model"
                  :state="state('pointOfRefuel')"
                  trim></b-form-input>

    <b-form-invalid-feedback id="point-of-refuel-input-feedback">
        <div v-for="error in errors('pointOfRefuel')" :key="error.error">
            {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('DIESEL_CONTROL_POINT_OF_REFUEL_LABEL')},})  | capitalize}}
        </div>
    </b-form-invalid-feedback>
</b-form-group>
<!-- pointOfRefuel text input -->

<!-- turns -->
<b-form-group
    :label="$t('FORMS_MULTI_SELECT_LABEL', {catalog: $t('TURN')}) | capitalize" 
    label-for="turn-input"
    :state="state('turns')">
        <turn-single-selector 
            id="turn-input" 
            :state="state('turns')" 
            :errors="errors('turns')"
            v-model="$v.form.turns.$model">
</turn-single-selector>
    </b-form-group>
<!-- turns -->
<!-- routes -->
<b-form-group
    :label="$t('FORMS_MULTI_SELECT_LABEL', {catalog: $t('ROUTE')}) | capitalize" 
    label-for="route-input"
    :state="state('routes')">
        <route-single-selector 
            id="route-input" 
            :state="state('routes')" 
            :errors="errors('routes')"
            v-model="$v.form.routes.$model">
</route-single-selector>
    </b-form-group>
<!-- routes -->
<!-- drivers -->
<b-form-group
    :label="$t('FORMS_MULTI_SELECT_LABEL', {catalog: $t('DRIVER')}) | capitalize" 
    label-for="driver-input"
    :state="state('drivers')">
        <driver-single-selector 
            id="driver-input" 
            :state="state('drivers')" 
            :errors="errors('drivers')"
            v-model="$v.form.drivers.$model">
</driver-single-selector>
    </b-form-group>
<!-- drivers -->
<!-- company -->
<b-form-group
    :label="$t('FORMS_MULTI_SELECT_LABEL', {catalog: $t('COMPANY')}) | capitalize" 
    label-for="company-input"
    :state="state('company')">
        <company-single-selector 
            id="company-input" 
            :state="state('company')" 
            :errors="errors('company')"
            v-model="$v.form.company.$model">
</company-single-selector>
    </b-form-group>
<!-- company -->
<!-- travel-programmings -->
<b-form-group
    :label="$t('FORMS_MULTI_SELECT_LABEL', {catalog: $t('TRAVEL')}) | capitalize" 
    label-for="travel-input"
    :state="state('travels')">
        <travel-single-selector 
            id="travel-input" 
            :state="state('travels')" 
            :errors="errors('travels')"
            v-model="$v.form.travels.$model">
</travel-single-selector>
    </b-form-group>
<!-- travel-programmings -->
          <b-button class="mt-2" type="submit" :disabled="saving" variant="primary">
            <b-icon v-if="saving" icon="circle-fill" animation="throb" class="mr-2"></b-icon>
            <span v-if="saving">{{ $t('FORMS_SAVING_LABEL')  | capitalize}}</span>
            <span v-else>{{ $t('FORMS_SAVE_LABEL')  | capitalize}}</span>
          </b-button>
        </b-form>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import {numeric,minLength,maxLength} from "vuelidate/lib/validators";
import {Form, ProcessWithLoadingAndMessage} from "@/mixins";
import DieselControl from "@/diesel-control";
import Constants from "@/constants";
import TurnSingleSelector from "@/turns/TurnSingleSelector";import RouteSingleSelector from "@/routes/RouteSingleSelector";import DriverSingleSelector from "@/drivers/DriverSingleSelector";import CompanySingleSelector from "@/companies/CompanySingleSelector";import TravelSingleSelector from "@/travel-programmings/TravelProgrammingSingleSelector"

export default {
  name: "DieselControlForm",
  components: {TurnSingleSelector,RouteSingleSelector,DriverSingleSelector,CompanySingleSelector,TravelSingleSelector},
  mixins: [Form, ProcessWithLoadingAndMessage],
  methods: {
    async doSave(id, data) {
      return id ? await DieselControl.update(id, data) : await DieselControl.create(data);
    },
    getData() {
      this.loadingFormData = true;
      DieselControl.findById(this.id)
          .then(resp => {
            this.form.travelCount = resp.data.travelCount;
this.form.pointOfRefuel = resp.data.pointOfRefuel;
this.form.turns = resp.data.turns;
this.form.routes = resp.data.routes;
this.form.drivers = resp.data.drivers;
this.form.company = resp.data.company;
this.form.travels = resp.data.travels;
            
          })
          .catch(e => {
            if (Constants.DEBUG) {
              console.error(e);
            }
            this.sendError('%loadingFormDataErrorMessage%', e);
          })
          .finally(() => {
            this.loadingFormData = false;
          });
    }
  },
  data() {
    return {
      loadingFormData: false,
      originals: {
        
      },
      form: {
        travelCount: null,pointOfRefuel: null,turns: null,routes: null,drivers: null,company: null,travels: null
      },
    editPage: 'DIESEL_CONTROL_EDIT_TITLE'
    }
  },
  validations: {
    form: {
      travelCount: {numeric},pointOfRefuel: {minLength: minLength(4),maxLength: maxLength(100)},turns: {},routes: {},drivers: {},company: {},travels: {}
    }
  }
}
</script>

<style scoped>

</style>
