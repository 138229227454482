<template>
  <div>
    <div v-if="loading" class="text-center text-danger my-2">
      <b-spinner class="align-middle"></b-spinner>
      <strong class="ml-2">{{ $t('LIST_LOADING_LABEL', {catalog: $t('TRAVELS')})  | capitalize}}</strong>
    </div>
    <b-select
        v-else
        id="travel-input" type="text"
        :state="state"
        @change="handleInput" class="w-100">
      <b-select-option :key="item.id" v-for="item in items" :value="item.name">{{$t(item)}}</b-select-option>
    </b-select>
    <b-form-invalid-feedback id="travel-input-feedback">
      <div v-for="error in errors" :key="error.error">
        {{ $t('FORMS_ERROR_FIELD_' + error.error, {...error.params, ...{field: $t('TRAVEL')},})  | capitalize }}
      </div>
    </b-form-invalid-feedback>
  </div>

</template>

<script>
import {VModel, SendsMessages} from "@/mixins";
import TravelProgramming from "@/travel-programmings/index";

export default {
  name: "TravelProgrammingSingleSelector",
  props: ['state', 'errors'],
  mixins: [VModel, SendsMessages],
  async mounted() {
    try {
      this.loading = true;
      this.items = await TravelProgramming.findAll({size: 1000, filter: {status: true}});
    } catch(e) {
      this.sendError('ERROR_LOADING_CATALOG', {}, e);
    } finally {
      this.loading = false;
    }
  },
  data() {
    return {
      loading: false,
      items: []
    }
  }
}
</script>

<style scoped>

</style>
